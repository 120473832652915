.page-create-campaign {
    .custom-input {
        margin-bottom: 10px;
        // min-height: 88px;
        .input-wrapper {
            margin-top: 0;
            height: 38px;
            padding-left: 0;
            input {
                padding-left: 8px;
            }
        }
        &.keyword-filter {
            .keyword-container {
                padding-left: 0;
                input {
                    padding-left: 8px;
                }
            }
        }
    }
}