.login-page{
    display: flex;
    //background-image: url(/core/assets/images/loginbg.png);
    background-size: cover;
}
.login-panel{
    width: 600px;
    background-color: #FFF;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    form{
        margin: 20vh auto;
        width: 300px;
    }
    .logo{
        margin: 0 auto;
        width: 250px;
        display: block;
    }
    h1{
        margin-top: 3rem;
        color: #898989;
        text-align: center;
        //font-family: Anton;
        font-size: 22px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
    }
}
.login-intro{
    width: calc(100vw - 600px);
    height: 100vh;
     background-image: url(/core/assets/images/loginbg.png);
    background-repeat: no-repeat;
    background-size: cover;
}