
@import 'responsive.scss';
@import "fonts";

$color1: #de0000;
$color2: #939393;
$color3: #980000;
$color4: #0f5393;
$color5: #c7c7c7;
$color6: rgba(206, 206, 206, 0.07);
$color7: #fafafa;
//$color1: #00296b;
//$color4: #003f88;
//$color3: #00509d;
//$color2: #fdc500;
//$color5: #ffd500;

$primary-color: $color1;
$line-light-color: #ebebeb;

@import "components/kdialog.scss";
@import "components/keditor.scss";
@import "components/kfilepicker.scss";

@import '~react-datetime-picker/dist/DateTimePicker.css';
@import '~react-calendar/dist/Calendar.css';
@import '~react-clock/dist/Clock.css';

a{
    color: $color4 !important;
    font-weight: 700;
    &:hover{
        color: $color1 !important;
    }
}

.custom-input{
    &:has(.error){
        .input-wrapper{
            border: 1px red solid;
        }
    }

    label{
        display: block;
        .required{
            color: red;
            font-weight: bold;
        }
    }
    
    .error{
        color: red !important;
        font-style: italic;
        font-size: 11px;
    }
    textarea{
        width: 100%;
        border: 0;
        outline: none;
        background-color: transparent;
    }
}
body{
    &:has(.kdialog){
        height: 100vh;
        overflow: hidden;
    }
}
.dashboard{
    display: flex;
    >aside{
        width: 200px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        border-right: 1px solid $line-light-color;
        background-color: #333333;
        //background-image: url("../images/3134.jpg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        &:before{
            position: absolute;
            content: '';
            display: block;
            background: rgba(0, 0, 0, 0.86);
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        .aside-container{
            position: relative;
            overflow-y: scroll;
            height: 100%;
            margin-right: -15px;
            padding-right: 0px;
            z-index: 2;
        }
        .logo{
            padding: 7px;
        }
        .session-profile{
            border-top: 1px solid $line-light-color;
            border-bottom: 1px solid $line-light-color;
            padding: 1rem;
            margin-bottom: 2rem;
            h4{
                font-size: 14px;
            }
            .nav{
                display: flex;
                flex-direction: column;
                li{
                    a{
                        //color: $color2;
                        color: #FFF;
                        text-decoration: none;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .navigation{
            padding: 0;
            margin-top: 2rem;
            li{
                list-style: none;
                a{
                    padding: .75rem 1rem;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: #333333;
                    >span{
                        display: flex;
                        &.icon{
                            width: 32px;
                            justify-content: center;
                            i{
                                font-size: 14px;
                                color: #c5c5c5;
                            }
                        }
                        &.label{
                            padding-left: .75rem;
                            color: #fff;
                            font-family: Arial;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
                &:hover, &.active{
                    >a.none{
                        background: #ff020259;
                        >span{
                            &.label{
                                //color: $primary-color;
                            }
                        }
                    }
                    >a.link{
                        background-color: $primary-color;
                        >span{
                            &.icon{
                                i{
                                    color: #FFF;
                                }
                            }
                            &.label{
                                color: #FFF;
                            }
                        }
                    }
                    >ul{
                        padding-left: 0;
                        height: auto;
                        overflow: hidden;
                        >li{

                        }
                    }
                }
                >ul{
                    padding-left: 0;
                    height: 0;
                    overflow: hidden;
                    >li{

                    }
                }
                &.division{
                    height: 2rem;
                }
            }
        }
    }
    >.wrapper{
        width: calc(100% - 200px);
        margin-left: 200px;
        min-height: 100vh;
        background-color: $color7;
        //padding-top: 60px;
        .header{
            padding: 1.25rem 3rem;
            background: $color7;
            //position: fixed;
            //width: calc(100% - 200px);
            //left: 200px;
            //top: 0;
            //z-index: 999;
            h2{
                color: $color4;
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                margin: 0;
            }
            .header-menu{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                span{
                    display: flex;
                    margin-right: 1rem;
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:hover{
                        color: $color1;
                    }
                    i{
                        font-size: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .content{
            padding: 1rem 2rem;
            h1{
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 2.5rem;
                display: flex;
                align-items: center;
                .go-back{
                    font-size: 13px;
                    font-weight: normal;
                    cursor: pointer;
                    text-transform: none;
                    display: block;
                    margin-right: 1.5rem;
                    border: 1px solid;
                    padding: 7px 16px;
                    border-radius: 4px;
                    &:hover{
                        color: $color1;
                    }
                }
            }
            
        }
        .jumbotron{
            margin: 3rem 0;
            background-color: $color3;
            color: #FFF;
            padding: 2rem;
        }
    }
}

.btn-search{
    color: #FFF; 
    background-color: #6a6a6a;
    &:hover{
        color: #FFF; 
        background-color: #3b3b3b;
    }
}

.btn-create{
    color: #FFF; 
    background-color: $color1;
    &:hover{
        color: #FFF; 
        background-color: $color3;
    }
}

.mng-filter{
    display: flex;
    align-items: start;
    >div{
        margin-right: 20px;
        min-width: 300px;
    }
    .btn{
        margin-top: 25px;
        padding: 7px 16px;
    }
    label{
        margin-bottom: 7px;
    }
    input{
        padding: 6px 16px;
    }
}

.card{
    border: 1px solid #ebebeb;
    .card-body{

    }
}

.keyword-filter{
    input{
        border: 0;
        outline: none;

        &.has-keyword {
            width: 120px;
        }
    }
    .keyword-container{
        padding: 2px 7px;
        border: 1px solid $line-light-color;
        background-color: #FFF;
        border-radius: 4px;
        width: auto;
        display: flex;
        align-items: center;
        .keyword-list{
            display: flex;
            overflow: auto;
            .keyword-item{
                align-items: center;
                background-color: #e6e6e6;
                border-radius: 4px;
                margin-right: 5px;
                padding: 5px;
                display: flex;
                font-size: 12px;
                span{
                    white-space: nowrap;
                }
                i{
                    margin-left: 5px;
                    cursor: pointer;
                    &:hover{
                        color:red;
                    }
                }
            }
        }
    }
}

.table-dataset{
    .table-config{
        display: flex;
        justify-content: space-between;
        align-items: center;
        select{
            margin: 0 5px;
            border: 1px solid $line-light-color;
            cursor: pointer;
        }
        .left{
            
        }
        .right{
            text-align: right;
            span{
                padding: 0 16px;
                cursor: pointer;
                &:last-child{
                    padding-right: 0;
                }
            }
        }
    }
    thead{
        border: 1px solid transparent;
        td{
            padding: 0;
            padding-bottom: 16px;
        }
    }
    .table-header{
        border: 1px solid transparent;
        th{
            padding: 16px;
            background-color: #f5f5f5;
        }
    }
    tbody {
        tr{
            td{
                padding: 16px;
                border-bottom: solid 1px $line-light-color;
                font-size: 13px;
                &.depth-1{

                }
                &.depth-2{
                    position: relative;
                    padding-left: 60px;
                    &:before{
                        content: '';
                        position: absolute;
                        display: inline-block;
                        height: 32px;
                        width: 30px;
                        top: 50%;
                        transform: translateY(-30%);
                        left: 16px;
                        background-image: url(../images/nl-bg.svg);
                        background-repeat: no-repeat;
                    }
                }
                &.depth-3{
                    position: relative;
                    padding-left: 90px;
                    &:before{
                        content: '';
                        position: absolute;
                        display: inline-block;
                        height: 32px;
                        width: 60px;
                        top: 50%;
                        transform: translateY(-30%);
                        left: 16px;
                        background-image: url(../images/nl-bg.svg);
                        background-repeat: no-repeat;
                    }
                }
                &.depth-4{}
                h5{
                    font-size: 13px;
                    margin-bottom: auto;
                    font-weight: bold;
                    &+em{
                        color: #8b8b8b;
                    }
                }
                .actions{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-end;

                    a{
                        visibility: hidden;
                        cursor: pointer;
                        text-decoration: none !important;
                        display: block;
                        margin-left: 16px;
                        i{}
                        span{}
                    }
                }
            }

        }
    }
    &.table-hover{
        tbody{
            tr{
                &:hover{
                    td{
                        background-color: $color6;
                        --bs-table-color-state: none;
                        --bs-table-bg-state: none;
                        .actions{
                            a{
                                visibility: visible;
                            }
                        }
                    }

                }
            }
        }
    }
}

.pagination{
    ul{
        margin-top: 2rem;
        margin-bottom: 3rem;
        padding: 0;
        display: flex;
        li{
        list-style: none;
        &.selected{
            a{
            background-color: $color1;
            color: #FFF !important;
            }
        }
        a{
            display: block;
            padding: 7px;
            background-color: #e7e7e7;
            border-radius: 4px;
            text-decoration: none;
            color: #333 !important;
            margin: 3px;
            min-width: 32px;
            text-align: center;
            &:hover{
                background-color: $color1;
                color: #FFF !important;
            }
        }
        }
    }
  }

.form-panel{
display: flex;
flex: 7 3;
margin: 0 -2rem;
.form-left-panel{
    flex: 7;
    padding: 0 2rem;

}
.form-right-panel{
    border-left: 1px solid #ebebeb;
    flex: 3;
    padding: 0 2rem;
}
.form-section{
    padding: 2rem 0;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    width: 100%;

    &:first-child{
        padding-top: 0;
    }
    .left{
        flex-basis: 25%;
        padding-right: 2rem;
    }
    .right{
        flex-basis: 75%;
    }
    h4{
        font-size: 14px;
        font-weight: bold;
    }
    p{
        font-size: 13px;
        color: #6a6a6a;
    }
}
}

.input-wrapper, .k-file-input{
margin-top: .75rem;
border: 1px solid $line-light-color;
display: flex;
align-items: center;
padding: 7px 16px;
background-color: #FFF;
&:has(input:disabled){
    background-color: #f9f9f9;
}
&.has-prefix{}
&.has-affix{}
input{
    width: 100%;
    padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
}
.prefix, .affix{
    color: #aaaaaa;
}
}

.image-option-container{
padding-top: 1rem;
.image-option-item{
    display: inline-block;
    width: 180px;
    height: 100px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    input{
        display: none;
        &:checked{
            &+.image-option-image{
                border: 2px solid $color1;
                opacity: 1;
            }
        }
    }
    .image-option-image{
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;
        border: 2px solid $line-light-color;
        opacity: .5;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        span{
            display: block;
            margin-top: 7px;
        }
    }
}
.disabled{
    position: absolute;
    inset: 0 0 0 0;
}
}

.seo-preview{
    // margin-bottom: 2rem;
    // padding-bottom: 2rem;
    // border-bottom: 1px solid $line-light-color;

    // margin-top: 2rem;
    // padding-top: 2rem;
    // border-top: 1px solid $line-light-color;

    .og-image{
        height: 150px;
        overflow: hidden;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .og-link{
        color: $primary-color;
    }
    .og-title{
        font-size: 16px;
        font-weight: bold;
    }
    .og-description{
        
    }
}

.form-buttons{
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid $line-light-color;
    span{
        cursor: pointer;
        display: inline-block;
        margin-left: 2rem;
    }
}

.react-datetime-picker{
    margin-top: 7px;
    .react-datetime-picker__wrapper{
        background-color: #FFF;
        border: 1px solid $line-light-color;
        width: 100%;
        padding: 5px 16px;
    }
}

.offcanvas{
    &:has(.create-panel){
        width: 50vw;
    }
    .create-panel{
        background-color: #f5f5f5;
        .form-panel{
            margin: 0;
        }
    }
}

.splash-screen{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading{
        width: 300px;
        height: 300px;
    }
}

.loading-component{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading{
        height: 200px;
        width: 200px;
        // max-width: 600px;
    }
}

.popover-container{
    padding: 1rem;
    border: 0;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    h4{
        font-size: 14px;
        margin-bottom: 1rem;
    }
    ul{
        padding: 0;
        li{
            list-style: none;
            label{
                padding: .25rem 0;
                cursor: pointer;
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
}

.custom-select2{
    >div>div{
        border: 1px solid $line-light-color;
        border-radius: 0;
    }
}


.widget {
    padding: 1rem;
    margin: 1rem;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid $line-light-color;
    border-radius: 7px;

    &:hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    }

    .widget-icon {
        // CSS cho phần icon trong widget
        position: absolute;
        font-size: 5rem;
        margin-bottom: 0;
        bottom: 1rem;
        left: 1rem;
    }
    .widget-title {
        font-weight: bold;
    }
    .widget-value {
        // CSS cho phần giá trị trong widget
        text-align: right;
        margin: 2rem 0;
        .current-value {
            // CSS cho giá trị hiện tại
            label {
                // CSS cho nhãn của giá trị hiện tại
                color: #8b8b8b;
            }
            strong {
                // CSS cho giá trị số mạnh của giá trị hiện tại
                font-size: 2rem;
                color: $color1;
            }
        }
        .last-value {
            // CSS cho giá trị cuối cùng
            label {
                // CSS cho nhãn của giá trị cuối cùng
                color: #8b8b8b;
            }
            strong {
                // CSS cho giá trị số mạnh của giá trị cuối cùng
                font-size: 1rem;
                color: red;
            }
        }
    }
    &.info {
        // CSS cho widget có class "info"
        background: #ffffff;
        color: #0060b0;
    }
    &.warning {
        // CSS cho widget có class "info"
        background: #ffffff;
        color: #ff9f00;
    }
    &.success {
        // CSS cho widget có class "info"
        background: #ffffff;
        color: $primary-color;
    }
}


details{
    >summary{
        font-size: 1.5rem;
        font-weight: 500;
    }
}

h4{
    span.label{
        padding: 5px;
        background: #deebff;
    }
    em.clickable{
        font-size: 75%;
        color: #e00000;
        cursor: pointer;
        //text-transform: lowercase;
        &:hover{
            color: $color1
        }
    }
}

.table-form{
    td {
        border: 1px solid #e1e1e1;
        padding: 1rem;
        .active {
            color: #FFF;
            padding: 5px;
        }
    }
    th{
        background: #f6f6f6;
        border: 1px solid #e1e1e1;
        padding: 1rem;
    }
}

.cwidgets{
    display: flex;
    margin-top: 1rem;
    justify-content: center;
}
.cwidget{
    //border: 1px solid #ebebeb;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    padding: 1.5rem;
    padding-left: 100px;
    position: relative;
    //margin-right: -1px;
    overflow: hidden;
    margin-right: 2rem;
    .icon{
        position: absolute;
        font-size: 60px;
        left: 1rem;
        bottom: 1rem;
        color: #b7b7b76b;
    }
    .label{
        font-weight: bold;
        text-align: center;
    }
    .value{
        text-align: center;
        >strong{
            font-size: 2rem;
        }
        .up{
            >i{
                color: #3D9700;
            }
            >span{
                font-weight: bold;
                color: #3D9700;
            }
            font-size: 11px;
        }
    }
}

.form-filter {
    input {
        width: 100%;
        padding: 6px 16px;
    }
}

.pagination  {
    ul {
        li {
            &.disabled {
                opacity: .5;
                pointer-events: none;
            }
        }
    }
}
