.user-detail {
  .card-body {
    .user-detail-tab {
      border-bottom: none !important;
      li {
        margin-right: 2px;
        button {
          background-color: #DFDFDF;
          border: none !important;
          border-top-left-radius: unset !important;
          border-top-right-radius: unset !important;
          color: #000;
          &.active {
            background-color: #ffffff !important;
            color: #000;
            font-weight: 700;
          }
        }
      }

    }
    .list-group-flush{
      padding: 0px;
      .list-group-item{
        cursor: pointer;
        padding: 5px;
        color: #000000;
        font-weight: 700;
        &.active{
          background-color: #DFDFDF;
        }
      }
    }
  }
}