.coupon_detail{
  //top: 10vh;
  .kdialog-content{
    width: 60vw;

    .table{
      th, td{
        padding: 10px;
        border: 1px solid #ababab;
      }
      th{
        background: #6a6a6a;
        color: #FFF;
      }
      .division{
        td{
          border: 0;
        }
      }
    }
  }
}

.settlement-panel{
  //top: 10vh;
  .table{
    th, td{
      padding: 10px;
      border: 1px solid #ababab;
    }
    th{
      background: #6a6a6a;
      color: #FFF;
    }
    .division{
      td{
        border: 0;
      }
    }
  }
}