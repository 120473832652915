body{
  &:has(.chat-container){
    height: 100vh;
    overflow: hidden;
  }
}

.chat-container{
  padding-left: 10px;
  padding-right: 10px;
  background: #FFF;
  //font-size: 12px;
  .border{
    border-collapse: collapse;
    border: 1px solid #f3f3f3 !important;
    border-bottom: 0 !important;
    border-right: 0 !important;

    &:nth-child(3), &:nth-child(6){
      border-right: 1px solid #f3f3f3 !important;
    }

    &:nth-child(4), &:nth-child(5), &:nth-child(6){
      border-bottom: 1px solid #f3f3f3 !important;
    }
  }
  #conversation-group{
    #conversation-list {
      scrollbar-width: thin;
    }

    a{
      text-decoration: none;
    }
    .pagination{
      ul{
        margin:0;
        margin-top: 1.5rem;
        margin-right: 1rem;

        li {
          a {
            font-size: 10px;
            font-weight: normal;
            padding: 5px;
          }
        }
      }
    }
    .conversation-item{
      display: flex;
      padding: 1rem 1.5rem;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
      &.active{
        background: #eeeeee;
      }
      .conversation_thumbnail{
        display: block;
        // flex: 1;
        width: 60px;
        .avatar{
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          display: inline-block;
          margin-left: -15px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          &:first-child{
            margin-left: 0;
          }
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      .conversation_subject{
        flex: 5;
        h5{
          font-size: 13px;
          font-weight: bold;
          color: #000;
        }
        p{
          color: #5e5e5e;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
      .conversation_time{
        flex: 3;
        text-align: right;
        font-size: 10px;
        font-weight: normal;
        color: #5e5e5e;
      }
    }
  }
}

.conversation-messages{
  #message-list {
    scrollbar-width: thin;
  }
  .message-item{
    padding: 1rem 1.5rem !important;
    .message{
      .info{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: center;


        .avatar{
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          display: inline-block;
          margin-left: -15px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          &:first-child{
            margin-left: 0;
          }
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        h5{
          margin-left: 1rem;
          font-size: 13px;
          font-weight: bold;
        }
      }
      .message-content{
        // margin-right: 10rem;
        background: #FFF6F6;
        -webkit-border-radius: 10px;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius: 10px;
        -moz-border-radius-topleft: 0;
        border-radius: 10px;
        border-top-left-radius: 0;
        padding: 1rem 1.5rem;
        width: fit-content;
        //box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      }
      .message-time{
        margin-left: calc(32px + 1rem);
        margin-top: .7rem;
        font-size: 10px;
        font-weight: normal;
        color: #5e5e5e;
      }

      a{
        font-weight: normal;
        font-size: 11px;
        text-decoration: none;
        color: #0d6efd;
      }

      &.message-user {
        padding-right: 20%;
        .message-content {
          margin-left: calc(32px + 1rem);
        }
      }
      &.message-shop {
        padding-left: 20%;
        .info {
          flex-direction: row-reverse;
          h5{
            margin-left: 0;
            margin-right: 1rem;
            text-align: right;
          }
        }

        .message-content {
          // margin-left: 10rem;
          margin-right: calc(32px + 1rem);
          -webkit-border-radius: 10px;
          -webkit-border-top-right-radius: 0;
          -moz-border-radius: 10px;
          -moz-border-radius-topright: 0;
          border-radius: 10px;
          border-top-right-radius: 0;
          background: #F5F5F5;
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          justify-content: end;
          width: fit-content;
          float: right;
        }
        .message-time{
          margin-left: 10rem;
          margin-right: calc(32px + 1rem);
          font-size: 10px;
          font-weight: normal;
          color: #5e5e5e;
          text-align: right;
        }
      }
    }
  }
}

.conversation-info{
  padding: 1.5rem;
  .cursor-pointer{
    cursor: pointer;
  }
}








.page-chat {
  .pagination {
    ul {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    .previous {
      display: none;
    }
    .next {
      display: none;
    }
  }

  .form-filter input {
    padding: 6px 2px;
  }

  .conversation-item {
    text-decoration: none;
    color: #212529 !important;

    &.active {
      background: #ff818159 !important;
    }

    &:hover {
      background: #ff818159 !important;
    }

    .text-muted {
      font-weight: normal;
    }
  }

  .loading {
    z-index: 999;
    top: 0;
    left: 0;
    background: #ffffff80;
  }

  .input-wrapper {
    margin-top: 19px;
    padding: 0;

    input {
      padding: 6px 15px;

      &:disabled {
        background: #ccc;
      }
    }
  }

  .object-fit-cover {
    object-fit: cover;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .message-item {
    &.cursor-pointer {
      &:hover {
        background-color: #ff818159;
      }
    }
  }
}
