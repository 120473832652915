.kdialog{
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border: 2px solid #FFF;
  border-radius: .5rem;
  &.iframe{
    top: 50%;
    transform: translate(-50%, -50%);
    .kdialog-content{
      padding: 0;
      width: unset;
      iframe{
        width: 1200px;
        height: 600px;
      }
    }
  }
  &.warning{
    .kdialog-icon{
      color: #ff9f00;
    }
    .kdialog-header{
      h3{
        color: #ff9f00;
      }
    }
    .kdialog-actions{
      .btn{
        &.btn-submit {
          background-color: #ff9f00;
        }
      }
    }
  }
  &.success{
    .kdialog-icon{
      color: #008026;
    }
    .kdialog-header{
      h3{
        color: #008026;
      }
    }
    .kdialog-actions{
      .btn{
        &.btn-submit {
          background-color: #008026;
        }
      }
    }
  }
  &.info{
    .kdialog-icon{
      color: #0060b0;
    }
    .kdialog-header{
      h3{
        color: #0060b0;
      }
    }

  }
  &.danger{
    .kdialog-icon{
      color: #ff0000;
    }
    .kdialog-header{
      h3{
        color: #ff0000;
      }
    }
    .kdialog-actions{
      .btn{
        &.btn-submit {
          background-color: #ff0000;
        }
      }
    }
  }
  .kdialog-container{
    position: relative;
    overflow: hidden;
    border-radius: .5rem;
    .kdialog-icon{
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-2rem, 2rem);
      z-index: 0;
      i{
        font-size: 10rem;
        transform: rotate(-15deg);
        opacity: .1;
      }
    }
  }
  .kdialog-header{
    border-bottom: 1px solid $line-light-color;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .kdialog-content{
    position: relative;
    z-index: 1;
    width: 450px;
    padding: 1rem;
  }
  .kdialog-actions{
    border-top: 1px solid $line-light-color;
    padding-top: 1rem;
    margin-top: 1rem;
    .btn{
      color: #FFF;
      margin-left: .75rem;
      opacity: .75;
      &:hover{
        opacity: 1;
      }
      &.btn-cancel{
        background: #333333;
      }
      &.btn-submit{
        background: $primary-color;
      }
    }
  }
}

#overlay{
  display: none;
  position: fixed;
  inset: 0 0 0 0;
  background: rgba(0, 0, 0, 0.53);
  z-index: 999998;
}